<template>
  <v-dialog
    ref="dialog"
    v-model="modalTime"
    :return-value.sync="selectedTime"
    persistent
    full-width
    width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="selectedTime"
        prepend-icon="mdi-clock-outline"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="modalTime"
      v-model="selectedTime"
      format="24hr"
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modalTime = false">{{$t('common.cancel')}}</v-btn>
      <v-btn text color="primary" @click="handleChangeTime">{{$t('common.ok')}}</v-btn>
    </v-time-picker>
  </v-dialog>
</template>


<script>
  export default {
    name: 'CustomtimePicker',
    props: {
      value: {
        type: String,
        required: true
      }
    },
    data: () => ({
      modalTime: false,
      selectedTime: null
    }),
    computed: {
      enterpriseColor () {
        return this.$store.state.app.enterpriseColor
      }
    },
    watch: {
      value (v) {
        if (v) this.selectedTime = v
      }
    },
    mounted () {
      this.selectedTime = this.value ? this.value : '#fff'
    },
    methods: {
      handleChangeTime () {
        this.$refs.dialog.save(this.selectedTime)
        this.$emit('input', this.selectedTime)
      }
    }
  }
</script>
